import {useToast} from "vue-toastification";
import {useAbility} from "@casl/vue";

export default defineNuxtRouteMiddleware((to, from) => {
    const toast = useToast()
    const {can} = useAbility()
    const {$i18n,$config} = useNuxtApp()
    // @ts-ignore
    if ([true, 'enabled'].includes($config.public.features[to.meta.feature]) || can(to.meta.ability, "pre_release_features")) {
        return true
    }
    toast.error($i18n.t('auth.route.notAllowed'))
    return abortNavigation()
});